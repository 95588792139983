<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group
            class="mr-1 mb-md-0"
          >
          <!-- <b-button
            v-b-modal.modal-1
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="addData"
          >
            เติมเครดิต
          </b-button> -->
          </b-form-group>
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="editData(data.item)"
            />
            <b-tooltip
              title="Preview Invoice"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
        <template #cell(topup)="data">
          <div class="text-nowrap">
            <feather-icon
              v-if="data.item.status === 'waiting'"
              :id="`invoice-row-${data.item.id}-preview-icon`"
              v-b-modal.modal-1
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="topup(data.item)"
            />
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        title="เติมเครดิต"
        ok-title="เติมเครดิต"
        @ok="submit"
      >
        <b-form-group>
          <v-select
            v-model="member"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="username"
            :options="optionsMember"
          />
        </b-form-group>
        <b-form-group
          label="จำนวนเงินที่ฝาก"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
            <!-- <feather-icon icon="CheckSquareIcon" /> -->
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              v-model="amount"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import {
  BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, VBToggle, BOverlay, BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      show: true,
      depositdata: [],
      smsData: '',
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'sender', label: 'sender' },
        { key: 'message', label: 'message' },
        { key: 'time', label: 'เวลา' },
        { key: 'amount', label: 'จำนวนเงิน' },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'topup', label: 'เติมเครดิต' },

      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.getSMS()
    }, 30000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.getSMS()
  },
  methods: {
    topup(data) {
      this.smsData = data
      this.amount = data.amount
      // this.getUserslist()
    },
    getUserslist() {
      this.$http
        .get('/users/list')
        .then(response => {
          this.show = false
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getSMS() {
      this.$http
        .get('/sms/listdepAll')
        .then(response => {
          this.show = false
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    submit() {
      this.show = true
      const formData = {
        username: this.member.username,
        uid: this.member.id,
        amount: this.amount,
      }
      this.$http
        .post('/deposit/store', formData)
        .then(() => {
          this.$http
            // eslint-disable-next-line no-underscore-dangle
            .get(`/sms/updatesuccess/${this.smsData._id}`)
            .then(() => {
              this.show = false
              this.getSMS()
            })
            .catch(error => console.log(error))
          this.username = ''
          this.amount = ''
          this.Success('เติมเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
